import { defineStore } from "pinia"
import { useTokenStore } from "./token"
import { map, uniq } from "lodash"
import axios from "axios"
import jwt from "@/utils/jwt"
import { toastController } from "@ionic/core"

const root = "culture-kit"

interface AnswersInput {
  topic: string
  question: string
  answer: string
  hide?: boolean
}

export const useCultureKitStore = defineStore("culturekit", {
  state: () => ({
    answer_id: "",
    topic_id: "",
    topic: "",
    answer: "",
    status: "",
    answers: <AnswersInput[]>[],
    initial_answers: {
      answer_id: "",
      account_id: "",
      topic_id: "",
      topic: "",
      answer: "",
      answers: [],
      status: "",
    },
  }),
  getters: {
    labels: (state) => uniq(map(state.answers, "topic")),
  },
  actions: {
    /**
     * retrieve questions
     * @param slug - topic slug
     * @param token - account_id
     * @param withToken - with token
     */
    async retrieveQuestions(slug: string, token?: string) {
      try {
        const config = <any>useTokenStore().normalHeader
        if (token) config.params = { token }
        else delete config.params
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/questions/${slug}`, config)
        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          this.topic_id = data.topic_id
          if (data.answer_id) {
            this.answer_id = data.answer_id
            this.status = data.status
            this.initial_answers = data
            if (data.answers) this.answers = data.answers
            if (data.answer) {
              this.topic = data.topic
              this.answer = data.answer
            }
          } else {
            if (data.topic) {
              this.topic = data.topic
              this.answer = ""
            }
            if (data.questions)
              this.answers = map(data.questions, (obj) => ({
                ...obj,
                answer: "",
              }))
            this.status = "draft"
          }
        }
        delete config.params
      } catch (err: any) {
        if (err.name === "AxiosError" && err.response.data.message === "Could not found answer document!") {
          this.retrieveQuestions(slug)
        } else {
          const message = err.name === "AxiosError" ? err.response.data.message : "Something went wrong!"
          return message
        }
      }
    },
    async saveAnswer(nbf: number, token: string, type: string) {
      try {
        const header = useTokenStore().normalHeader
        const tokenizedPayload = jwt.generateToken(
          {
            topic_id: this.topic_id,
            account_id: token,
            status: this.status,
            answers: this.answers,
            topic: this.topic,
            answer: this.answer,
          },
          nbf
        )
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/topics/${type}`, tokenizedPayload, header)
        const message = this.status === "active" ? "Submitted" : "Progress saved."
        return message
      } catch (err: any) {
        return err.response?.data?.message
      }
    },
    async updateAnswer(nbf: number, token: string, type: string) {
      try {
        const header = useTokenStore().normalHeader
        const tokenizedPayload = jwt.generateToken(
          {
            answer_id: this.answer_id,
            topic_id: this.topic_id,
            answers: this.answers,
            account_id: token,
            topic: this.topic,
            answer: this.answer,
            status: this.status,
          },
          nbf
        )
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/answers/${type}/update`, tokenizedPayload, header)
        const message = this.status === "active" ? "Submitted." : "Progress saved."
        return message
      } catch (err: any) {
        return err.response?.data?.message
      }
    },
  },
})
