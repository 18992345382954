import WorksonaResultPage from "@/views/survey/WorksonaResultPage.vue"
import QuestionPage from "@/views/survey/QuestionPage.vue"
import { IonNav } from "@ionic/vue"

export default [
  {
    path: "/survey/:type",
    name: "Survey Question Page",
    component: IonNav,
    props: {
      root: QuestionPage,
    },
    meta: {
      requiresAuth: true,
      guestOnly: false,
    },
  },
  {
    path: "/worksona/result/:type",
    name: "Worksona Result Page",
    component: IonNav,
    props: {
      root: WorksonaResultPage,
    },
    meta: {
      requiresAuth: false,
      guestOnly: false,
    },
  },
]
