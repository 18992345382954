import axios from "axios"
import { toastController } from "@ionic/core"

export default {
  /**
   * get place by latitude and longitude
   * @param lat - latitude
   * @param lng - longitude
   * @returns an object of place details
   */
  async getGeocode(lat: string, lng: string) {
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBy2vESb3C2axYldQt8-AJn0hJF1LS1w6Q`)
      return response.data
    } catch (error: any) {
      const toast = await toastController.create({
        message: error.response?.data?.message || "Something went wrong",
        duration: 3000,
        position: "top",
        color: "danger",
      })
      await toast.present()
    }
  },
  /**
   * gets a sorted address by place details
   * @param place - place details
   * @returns - an object of sorted address
   */
  getAddress: (place: any) => {
    const address = {
      full_address: "",
      postal_code: "",
      street: "",
      city: "",
      state: "",
      country: "",
    }
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0]
      switch (componentType) {
        case "street_number": {
          address.full_address = `${component.long_name}`
          break
        }
        case "premise": {
          address.full_address += address.full_address.length > 0 ? `, ${component.long_name}` : `${component.long_name}`
          break
        }
        case "neighborhood": {
          address.full_address += address.full_address.length > 0 ? `, ${component.long_name}` : `${component.long_name}`
          break
        }
        case "route": {
          address.full_address += address.full_address.length > 0 ? `, ${component.long_name}` : `${component.long_name}`
          address.street = `${component.long_name}`
          break
        }
        case "locality": {
          address.full_address += address.full_address.length > 0 ? `, ${component.long_name}` : `${component.long_name}`
          address.city = `${component.long_name}`
          break
        }
        case "administrative_area_level_2": {
          address.full_address += address.full_address.length > 0 ? `, ${component.long_name}` : `${component.long_name}`
          address.state = `${component.long_name}`
          break
        }
        case "country": {
          address.full_address += address.full_address.length > 0 ? `, ${component.long_name}` : `${component.long_name}`
          address.country = `${component.long_name}`
          break
        }
        case "postal_code": {
          address.postal_code = `${component.long_name}`
          break
        }
      }
    }

    return address
  },
}
