import { defineStore } from "pinia"
import { useTokenStore } from "./token"
import axios from "axios"

const root = "external"

export const useExternalStore = defineStore("external", {
  state: () => ({
    file: <any>Blob,
    folder: "generic-folder",
  }),
  actions: {
    /**
     * Calls for upload api and saves images to S3
     */
    async uploadFile() {
      try {
        const header = useTokenStore().authorizationHeader as any
        const formData = new FormData()
        formData.append("file", this.file as Blob)
        formData.append("folder", this.folder)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/upload/${this.folder == "file" ? "file" : "image"}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: header.headers.Authorization,
          },
        })
        return response.data
      } catch (err: any) {
        throw new Error(err?.response?.data?.message)
      }
    },
  },
})
