<template>
  <ion-page>
    <ion-content>
      <div data-view class="pb-24">
        <section class="bg-primary-violet text-white px-4 py-10 shadow-xl">
          <h1 class="text-3xl font-thin">Terms of Use</h1>
        </section>
        <section class="m-4 w-11/12 mx-auto">
          <h2 class="text-md text-2xl text-wizard-worksona font-bold text">Contract</h2>
          <div class="content mb-14">
            <p>Workbean is a recruitment marketing platform designed to provide companies a channel to showcase their people, workplace, and culture to attract the best talent.</p>
            <p>
              By signing up for Workbean, you are agreeing to enter into a legally binding contract with Workbean. If you do not agree with the Terms of this contract, do not click "Sign Up" or
              "Register" or any similar actions that will lead to creating an account with Workbean to access our services. If you wish to terminate the contract, you can do so at any time by closing
              your account by deactivating it from your account settings and no longer using our services.
            </p>
            <p>
              The Terms of Use may change over time and we will duly notify you for any changes that will materially change your rights. Otherwise, for minor updates and changes, we will update this
              page of our website. When you continue to use Workbean after the changes are applied and posted, you are agreeing to the modified terms.
            </p>
            <p>
              If there exists a written agreement with Workbean, superseding our Terms of Use, the provisions in the separate written agreement will apply upon agreement of both parties of the same.
            </p>
          </div>
          <h2 class="text-md text-2xl text-wizard-worksona font-bold">Services</h2>
          <div class="content mb-14">
            <p>
              Workbean services refer to the unique services offered to our three main users:
              <strong class="uppercase">job seekers</strong>,
              <strong class="uppercase">employed individuals</strong>
              or <strong class="uppercase">companies</strong>.
            </p>
          </div>
          <h2 class="text-md text-2xl text-wizard-worksona font-bold">Job Seekers</h2>
          <div class="content mb-14">
            <p>
              Creating an account with Workbean is the minimum requirement to view in-depth information about companies and participate in the Workbean social community. By creating an account with
              Workbean, you agree to use legitimate data and information about yourself without misrepresentation.
            </p>

            <ul class="list-disc pl-16">
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Job Seeker Account</strong>
                <p>
                  Once you have successfully signed up, you will receive an email with instructions on how to activate your Workbean Account. When you choose to activate, you will have access to your
                  personal dashboard that you can populate with your personal and professional information. You are encouraged to share information about yourself such as your internships, work
                  experiences, skills, profile photo, current, and past employers, etc. By sharing this information, you agree to be bound by this Terms of Use.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Service Eligibility</strong>
                <p>Our services are not suited for individuals below the age of 16.</p>
                <p>
                  If you do not meet the age criteria, kindly seek the advice and consent of your parent or legal guardian and send us an email at letstalk@tobehired.com with the subject line
                  "Creating a Workbean account for minor" with the attached signed letter from your parent or legal guardian.
                </p>
                <p>
                  You also agree that by signing up for Workbean, you will only maintain one account using your real name and identification. Violation of these terms will lead to the permanent
                  deactivation of your account.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Account Guidelines</strong>
                <p>
                  Workbean is designed to help you create and find opportunities, as a basic rule of thumb, Workbean is a professional platform whereby the content and communication must remain so.
                </p>
                <p>
                  Posting of violent, nude, discriminatory, hateful or sexually suggestive content is strictly prohibited. As a user, you are responsible for the content you upload and share.
                  Furthermore, you are prohibited from using Workbean as a tool to stalk, defame, bully, harass, abuse, threaten, intimidate or impersonate other individuals or entities. You agree not
                  to use Workbean for any illegal or unauthorized purposes.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Culture Page™</strong>
                <p>
                  As a job seeker, we understand that looking for the best place to work is most important. We have introduced our Culture Page™ to empower you to make see what it’s really like to
                  work at your potential employer and make an informed decision.
                </p>
                <p>
                  The Culture Page™ is designed to serve as a guide that can allow you to decide on your career move. Your viewing of the Culture Page™ is subject to your consent and you are free to
                  keep browsing different companies in the Workbean platform.
                </p>
                <p>There are five different tabs to browse from: Home, People, Jobs, About, and Tips.</p>
                <p>
                  The home tab allows you to get a first glance at the company’s overall culture and mission. You will see their benefits, amenities, and accessibility, while also seeing who forms
                  part of their leadership team.
                </p>
                <p>
                  The people tab lets you access videos of different employee roles in a selected company. You will have the opportunity to hear their stories and their description of their work, as
                  well as their view of their company. You will also see their real-time Instagram feed of their chosen hashtags.
                </p>
                <p>The jobs tab allows you to see available jobs in your selected company. Once clicked, you will be redirected to the company’s preferred job portal.</p>
                <p>
                  The about tab unlocks more information about your selected company. You will be able to see their mission and vision statements, core values and basic information such as their
                  office address. You will also see their real-time social media feeds.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Applying to Jobs</strong>
                <p>
                  As of January 2020, Workbean does not have a job application portal. If you are interested in applying for a job with your preferred employer, you may access the "Jobs" tab of the
                  Culture Page™ and see the available positions. Once you click, you will be redirected to the Company’s preferred job portal.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona"> Additional Features and Services </strong>
                <p>
                  Workbean will continue to add features and services for job seekers. If there are major features and services, we will duly notify all members and subscribers. Terms of Use will also
                  be updated on the same.
                </p>
              </li>
            </ul>
          </div>
          <h2 class="text-md text-2xl text-wizard-worksona font-bold">Employed Individuals</h2>
          <div class="content mb-14">
            <p>
              Creating an account with Workbean is the minimum requirement to view in-depth information about companies and participate in the Workbean social community. By creating an account with
              Workbean, you agree to use legitimate data and information about yourself without misrepresentation.
            </p>
            <ul class="list-disc pl-16">
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Employee Account</strong>
                <p>
                  Once you have successfully signed up, you will receive an email with instructions on how to activate your Workbean Account. When you choose to activate, you will have access to your
                  personal dashboard that you can populate with your personal and professional information. You are encouraged to share information about yourself such as your internships, work
                  experiences, skills, profile photo, current, and past employers, etc. By sharing this information, you agree to be bound by this Terms of Use.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Service Eligibility</strong>
                <p>Our services are not suited for individuals below the age of 16.</p>
                <p>
                  If you do not meet the age criteria, kindly seek the advice and consent of your parent or legal guardian and send us an email at
                  <a href="mailto:letstalk@tobehired.com">letstalk@tobehired.com</a>
                  with the subject line "Creating a Workbean account for minor" with the attached signed letter from your parent or legal guardian.
                </p>
                <p>
                  You also agree that by signing up for Workbean, you will only maintain one account using your real name and identification. Violation of these terms will lead to the permanent
                  deactivation of your account.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Account Guidelines</strong>
                <p>
                  Workbean is designed to help you create and find opportunities, as a basic rule of thumb, Workbean is a professional platform whereby the content and communication must remain so.
                </p>
                <p>
                  Posting of violent, nude, discriminatory, hateful or sexually suggestive content is strictly prohibited. As a user, you are responsible for the content you upload and share.
                  Furthermore, you are prohibited from using Workbean as a tool to stalk, defame, bully, harass, abuse, threaten, intimidate or impersonate other individuals or entities. You agree not
                  to use Workbean for any illegal or unauthorized purposes.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Culture Page™ </strong>
                <p>You will be able to access Culture Pages™ of other companies upon signing up. Terms of use are applicable similar to that of a Job Seeker.</p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Applying to Jobs</strong>
                <p>You will be able to view and apply to jobs upon signing up. Terms of use are applicable similar to that of a Job Seeker.</p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Company Rating</strong>
                <p>
                  As part of Workbean’s mission to usher talents to the best career decisions to become great contributors at work and in the community, we urge our users and reviewers to rate
                  companies objectively and accurately. It means that we encourage you to rate your current employers by carefully weighing both the strengths and weaknesses of the company.
                </p>
                <p>
                  Your privacy is very important to us. All your ratings and reviews will be kept anonymous and will not be disclosed to your employer nor other parties in any way that will lead to
                  your identity being traced.
                </p>
                <p>We do not take sides when it comes to disputes. We will not instigate a fact-finding activity when this arises and we will stay neutral at all times.</p>
                <p>We respect boundaries and we urge you to follow ours, too.</p>
                <p>Here are the guidelines for posting a review:</p>
                <ol>
                  <li>
                    <strong class="text-wizard-worksona">Be truthful.</strong>
                    <span
                      >We’re advocating the spread of authentic information to help job seekers make their best career decisions and we need your cooperation. When constructing reviews, be truthful
                      and fair while considering the person who will be benefiting from it. If an instance occurs that we detect a falsified review, we reserve the right to delete your review.</span
                    >
                  </li>
                  <li>
                    <strong class="text-wizard-worksona">Keep it concise.</strong>
                    <span
                      >Similar to original tweets, let’s limit it to 140 words. We want to encourage you to keep expressing your ideas briefly. We love a story but we value our users’ time, too.</span
                    >
                  </li>
                  <li>
                    <strong class="text-wizard-worksona">Make it clean.</strong>
                    <span
                      >We will not tolerate negativity in our platform. Keep profanities, foul language, threats of violence and discrimination against an individual out of your reviews. General
                      discussion about the workplace is allowed and we encourage you to give an informative recollection of your experience. If your reviews provide an indication of personal vendetta
                      against an employee or the company, we will reserve the right to delete your review.
                    </span>
                  </li>
                  <li>
                    <strong class="text-wizard-worksona">No name-dropping.</strong>
                    <span
                      >We love transparency but we also want to protect your privacy and our users’ too. If there are indications of a negative review against an identifiable person, we will reserve
                      the right to delete your review.</span
                    >
                  </li>
                  <li>
                    <strong class="text-wizard-worksona">No advertisements.</strong>
                    <span
                      >We will automatically delete a review that contains irrelevant or inappropriate promotional content that solicits customers or recruits talent. We will also reject reviews that
                      contain web links.</span
                    >
                  </li>
                  <li>
                    <strong class="text-wizard-worksona">Remember confidentiality.</strong>
                    <span
                      >When reviewing a company, always think about your Non-Disclosure Agreements (NDA). We will not accept reviews that reveal confidential, non-public internal company information.
                      This includes source codes, customer lists, manufacturing techniques, R&D activities, budgets, detailed financial results, and technical know-how.</span
                    >
                  </li>
                  <li>
                    <strong class="text-wizard-worksona">Be original.</strong>
                    <span>We will not accept plagiarized content. Any direct quotes from other sources like websites, SMS, social apps, or emails, will be deleted.</span>
                  </li>
                  <li>
                    <strong class="text-wizard-worksona"> Seek help from relevant authorities when needed.</strong>
                    <span
                      >Workbean will not act as a liaison between you and government agencies or local authorities. If you have any disputes, legal disagreements or if there are any misconduct or
                      illegal activities in your place of work, we strongly recommend you to approach the relevant authorities.
                    </span>
                  </li>
                </ol>
              </li>
            </ul>
          </div>
          <h2 class="text-md text-2xl text-wizard-worksona font-bold">Companies</h2>
          <div class="content mb-14">
            <p>
              Creating an account with Workbean is the minimum requirement to acquire your own Culture Page™ and participate in the Workbean social community. By creating an account with Workbean, you
              agree to use legitimate data and information about your company without misrepresentation.
            </p>
            <ul class="list-disc pl-16">
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Company Account</strong>
                <p>
                  Activating an account is simple. You can sign up through our Contact Us page and a representative from Workbean will be in touch. Once you have coordinated and paid for the Workbean
                  platform, you are required to submit all required information about your company that will form part of your Culture Page™.
                </p>
                <p>Once your page is up, job seekers from all over the world will now be able to find you on www.workbean.co/company/yourcompanyname.</p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Service Eligibility</strong>
                <p>Our platform is only available to our paying customers.</p>
                <p>If you wish to be on the Workbean platform, you will be required to choose a package and service type and proceed to payment.</p>
                <p>
                  You also agree that by signing up for Workbean, you will only maintain one account using your real name and identification. Violation of these terms will lead to the permanent
                  deactivation of your account.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Account Guidelines</strong>
                <p>
                  Workbean is designed to help you create and find opportunities, as a basic rule of thumb, Workbean is a professional platform whereby the content and communication must remain so.
                </p>
                <p>
                  Posting of violent, nude, discriminatory, hateful or sexually suggestive content is strictly prohibited. As a user, you are responsible for the content you upload and share.
                  Furthermore, you are prohibited from using Workbean as a tool to stalk, defame, bully, harass, abuse, threaten, intimidate or impersonate other individuals or entities. You agree not
                  to use Workbean for any illegal or unauthorized purposes.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Culture Page™</strong>
                <p>You will be able to create your own Culture Page™ upon signing up.</p>
                <p>
                  The Culture Page™ is designed to serve as a guide that can allow job seekers to decide on their career move. Your Culture Page™ content is subject to your consent and you are free to
                  collaborate on its content with the Workbean team.
                </p>
                <p>There are five different tabs to browse from: Home, People, Jobs, About, and Tips.</p>
                <p>
                  The <strong>home</strong> tab allows users to get a first glance at the company’s overall culture and mission. They will see your benefits, amenities, and accessibility, while also
                  seeing who forms part of your leadership team.
                </p>
                <p>
                  The <strong>people</strong> tab lets users access videos of different employee roles in your company. You will have the opportunity to share your people’s stories and the description
                  of their work, as well as their view of your company. You can also share a real-time Instagram feed of your company hashtag.
                </p>
                <p>The <strong>jobs</strong> tab allows users to see available jobs in your company. Once clicked, they will be redirected to your company’s preferred job portal.</p>
                <p>
                  The <strong>about</strong> tab unlocks more information about your company. Users will be able to your mission and vision statements, core values and basic information such as office
                  address. Users will also see your real-time social media feeds.
                </p>
              </li>
              <li class="mt-6">
                <strong class="text-xl font-bold text-wizard-worksona">Company Rating</strong>
                <p>
                  As part of Workbean’s mission to usher talents to the best career decisions to become great contributors at work and in the community, we urge our users and reviewers to rate
                  companies objectively and accurately. It means that we encourage users to rate their current employers by carefully weighing both the strengths and weaknesses of the company.
                </p>
                <p>
                  Our users’ privacy is very important to us. All the ratings and reviews will be kept anonymous and will not be disclosed to companies nor other parties in any way that will lead to
                  an individual’s identity being traced.
                </p>
                <p>We do not take sides when it comes to disputes. We will not instigate a fact-finding activity when this arises and we will stay neutral at all times.</p>
                <p>We respect boundaries and we urge you to follow ours, too. Our review guidelines state our rules on the company rating page and we designed it to protect all parties.</p>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </ion-content>
  </ion-page>
</template>
