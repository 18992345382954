import { defineStore } from "pinia"

export const useMetadataStore = defineStore("metadata", {
  state: () => ({
    details: {
      title: "Workbean",
      description: "Discover Southeast Asian's largest directory of company culture",
      robots: "index, follow",
      og: {
        title: "Workbean",
        type: "website",
        description: "Discover Southeast Asian's largest directory of company culture",
        image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
        url: window.location.href,
        site_name: "Workbean",
        first_name: "",
        last_name: "",
        author: "",
        published_time: "",
        modified_time: "",
        section: "",
        canonical: window.location.href,
      },
      twitter: {
        title: "Workbean",
        description: "Discover Southeast Asian's largest directory of company culture",
        image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
        site: window.location.href,
        creator: "Workbean",
      },
    },
    domain: "",
  }),
})
