import { z } from "zod"
import { toFormValidator } from "@vee-validate/zod"
import { each, includes } from "lodash"
const nonCorporateEmail = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "yahoo.co.uk",
  "yahoo.com.br",
  "yahoo.co.in",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net",
  "sfr.fr",
  "live.fr",
  "verizon.net",
  "live.co.uk",
  "googlemail.com",
  "yahoo.es",
  "ig.com.br",
  "live.nl",
  "bigpond.com",
  "terra.com.br",
  "yahoo.it",
  "neuf.fr",
  "yahoo.de",
  "alice.it",
  "rocketmail.com",
  "att.net",
  "laposte.net",
  "facebook.com",
  "bellsouth.net",
  "yahoo.in",
  "hotmail.es",
  "charter.net",
  "yahoo.ca",
  "yahoo.com.au",
  "rambler.ru",
  "hotmail.de",
  "tiscali.it",
  "shaw.ca",
  "yahoo.co.jp",
  "sky.com",
  "earthlink.net",
  "optonline.net",
  "freenet.de",
  "t-online.de",
  "aliceadsl.fr",
  "virgilio.it",
  "home.nl",
  "qq.com",
  "telenet.be",
  "me.com",
  "yahoo.com.ar",
  "tiscali.co.uk",
  "yahoo.com.mx",
  "voila.fr",
  "gmx.net",
  "mail.com",
  "planet.nl",
  "tin.it",
  "live.it",
  "ntlworld.com",
  "arcor.de",
  "yahoo.co.id",
  "frontiernet.net",
  "hetnet.nl",
  "live.com.au",
  "yahoo.com.sg",
  "zonnet.nl",
  "club-internet.fr",
  "juno.com",
  "optusnet.com.au",
  "blueyonder.co.uk",
  "bluewin.ch",
  "skynet.be",
  "sympatico.ca",
  "windstream.net",
  "mac.com",
  "centurytel.net",
  "chello.nl",
  "live.ca",
  "aim.com",
  "bigpond.net.au",
  "dispostable.com",
  "yopmail.com",
  "icloud.com",
  "30gigs.com",
]
const rules = {
  email: z.string().email().min(1, { message: "Oops! Email is required." }),
  corporateEmail: z
    .string()
    .email()
    .min(1, { message: "Oops! Email is required." })
    .refine(
      (val) => {
        const domain = val.match(/.+@(.+)/) as Array<any>
        return domain && domain[1] ? !includes(nonCorporateEmail, domain[1]) : true
      },
      {
        message: "Email must be a valid company email.",
      }
    ),
  stringOptional: z.string().optional(),
  stringRequired: z.string({ required_error: "Oops! This is required." }).min(1, { message: "Oops! This is required." }),
  sixDigitCode: z.string().min(6, { message: "Please input exactly six digits" }),
  checkBox: z.boolean({
    required_error: "Oops! This box needs a check mark.",
  }),
  password: z
    .string()
    .min(8)
    .regex(/.*[A-Z].*/, "Your password needs at least one uppercase character")
    .regex(/.*[a-z].*/, "Your password needs at least one lowercase character")
    .regex(/.*\d.*/, "Your password needs at least one number")
    .regex(/.*[`~<>?,./!@#$%^&*()\-_+="'|{}[\];:\\].*/, "Your password needs at least one special character."),
  number: z.string().min(1, "Required"),
  // mustAgree rules for validation
  mustAgree: z.boolean({
    required_error: "Please agree to the terms and conditions and privacy policy to proceed.",
  }),
} as any

interface schemaType {
  name: string
  rule: string
}

export const generateSchema = (condition: Array<schemaType>, confirm = { from: "", to: "", label: "" }) => {
  const generateRules = {} as any
  each(condition, (value) => {
    generateRules[value.name as string] = rules[value.rule]
  })
  if (confirm.from !== "" && confirm.to !== "") {
    const validationObject = z.object(generateRules).refine((data) => data[confirm.from] === data[confirm.to], {
      message: `${confirm.label ? confirm.label : "Field"} doesn't match`,
      path: [`${confirm.to}`],
    })
    return toFormValidator(validationObject)
  } else {
    return toFormValidator(z.object(generateRules))
  }
}
