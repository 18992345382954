import { createRouter, createWebHistory } from "@ionic/vue-router"
import { RouteRecordRaw } from "vue-router"
import { IonNav } from "@ionic/vue"
import WorkbeanWorld from "@/views/WorldFeed.vue"
import LoginRouter from "@/components/account-creation/LoginRouter.vue"
import SignupPage from "@/components/account-creation/SignupRouter.vue"
import SetPasswordPage from "@/views/SetPassword.vue"
import DetailsPage from "@/views/DetailsPage.vue"
import DiscoverPage from "@/components/discover-page/DiscoverPageRoute.vue"
import JobBoardPage from "@/views/JobBoardPage.vue"
import JobPage from "@/views/job-board/JobPost.vue"
import CompanyPage from "@/views/workbean-page/CompanyPage.vue"
import ResetPassword from "@/views/ResetPassword.vue"
import VerifyPage from "@/views/VerifyPage.vue"
import SearchPage from "@/views/job-board/SearchPage.vue"
import SearchCompanyPage from "@/views/SearchPage.vue"
import TalentProfilePage from "@/views/talent-profile/TalentProfile.vue"
import EditTalentProfile from "@/components/talent-profile/EditRoute.vue"
import AccountSecurity from "@/views/talent-account/AccountSecurity.vue"
import ModalContainer from "@/components/workbean-page/ModalContainer.vue"
import ResourcesPage from "@/views/resources/ResourcesPage.vue"
import survey from "./survey"
import SolutionsPage from "@/views/SolutionsPage.vue"
import ResourcesHomepage from "@/views/resources/ResourcesHomepage.vue"
import NotFound from "@/views/404Page.vue"
import EmployeeAmbassadorshipPage from "../views/question/EmployeeAmbassadorship.vue"
import ThoughtLeadershipPage from "../views/question/ThoughtLeadership.vue"
import PrivacyPolicy from "@/views/PolicyPage.vue"
import TermsAndCondition from "@/views/TermsPage.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "DiscoverPage",
    meta: {
      active: "discover",
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Best Companies to Work for in the Philippines | Workbean",
        description: "Find the best companies in the Philippines for you. Research company culture and experience the best hiring platform.",
        og: {
          title: "Best Companies to Work for in the Philippines | Workbean",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
          description: "Find the best companies in the Philippines for you. Research company culture and experience the best hiring platform.",
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Best Companies to Work for in the Philippines | Workbean",
          description: "Find the best companies in the Philippines for you. Research company culture and experience the best hiring platform.",
        },
      },
    },
    component: IonNav,
    props: {
      root: DiscoverPage,
    },
    children: [
      {
        path: "/filter",
        name: "Discover Page filter",
        component: SearchPage,
      },
    ],
  },
  {
    path: "/job-board",
    name: "Job Board Page",
    component: IonNav,
    props: {
      root: JobBoardPage,
    },
    meta: {
      active: "job",
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Job Vacancies & Openings - Job Board Philippines | Workbean ",
        description: "Workbean's job hiring platform will help you find opportunities for a new work from home, remote, or startup job within the Philippines. Explore job openings.",
        og: {
          title: "Job Vacancies & Openings - Job Board Philippines | Workbean ",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
          description: "Workbean's job hiring platform will help you find opportunities for a new work from home, remote, or startup job within the Philippines. Explore job openings.",
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Job Vacancies & Openings - Job Board Philippines | Workbean ",
          description: "Workbean's job hiring platform will help you find opportunities for a new work from home, remote, or startup job within the Philippines. Explore job openings.",
        },
      },
    },
    children: [
      {
        path: "/filter",
        name: "Discover Page filter",
        component: SearchPage,
      },
    ],
  },
  {
    path: "/workbean-world",
    name: "WorkbeanWorld",
    component: WorkbeanWorld,
    meta: {
      active: "world",
      requiresAuth: false,
      guestOnly: false,
    },
  },
  {
    props: {
      root: WorkbeanWorld,
    },
    path: "/workbean-world/:type/:id",
    name: "Post Page",
    component: ModalContainer,
    meta: {
      active: "world",
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Workbean World",
        og: {
          title: "Workbean World",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Workbean World",
        },
      },
    },
  },
  {
    path: "/resources/:type/:slug",
    name: "Resources Page",
    component: ResourcesPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
    },
  },
  {
    path: "/job-board/:company/:slug/:job",
    name: "Job Page",
    component: JobPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
    },
  },
  {
    path: "/solutions",
    name: "Solutions Page",
    component: SolutionsPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Employer Branding Consulting Company | Workbean",
        description: "Let's help you establish what makes your company unique by enhancing your employer branding strategies with Workbean's employer branding consultancy services.",
        og: {
          title: "Employer Branding Consulting Company | Workbean",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
          description: "Let's help you establish what makes your company unique by enhancing your employer branding strategies with Workbean's employer branding consultancy services.",
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Employer Branding Consulting Company | Workbean",
          description: "Let's help you establish what makes your company unique by enhancing your employer branding strategies with Workbean's employer branding consultancy services.",
        },
      },
    },
  },
  {
    path: "/company/:slug/:tab?",
    name: "Company Page",
    component: CompanyPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
    },
  },
  {
    path: "/search",
    name: "Search Page",
    component: IonNav,
    meta: {
      details: {
        title: "Search companies on Workbean",
        og: {
          title: "Search companies on Workbean",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Search companies on Workbean",
        },
      },
    },
    props: {
      root: SearchCompanyPage,
    },
  },
  {
    path: "/talent-profile/:user/:slug?/:tab?",
    name: "Talent Profile Page",
    meta: {
      requiresAuth: true,
      guestOnly: false,
    },
    component: IonNav,
    props: {
      root: TalentProfilePage,
    },
  },
  {
    path: "/talent-profile/edit",
    name: "Edit Talent Profile Page",
    component: EditTalentProfile,
  },
  {
    path: "/account-login-and-security",
    name: "Login & Security Page",
    component: AccountSecurity,
    meta: {
      requiresAuth: true,
      guestOnly: false,
    },
  },
  {
    path: "/resources/:type?",
    name: "Resources Homepage",
    component: IonNav,
    props: {
      root: ResourcesHomepage,
    },
    meta: {
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Resource Library - Workbean",
        og: {
          title: "Resource Library - Workbean",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Resource Library - Workbean",
        },
      },
    },
  },
  {
    path: "/ambassadorship/:slug",
    name: "Employee Ambassadorship Page",
    component: EmployeeAmbassadorshipPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
    },
  },
  {
    path: "/thought-leadership/:slug",
    name: "Thought Leadership Page",
    component: ThoughtLeadershipPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
    },
  },
  {
    path: "/login",
    name: "Login As",
    component: LoginRouter,
    meta: {
      requiresAuth: false,
      guestOnly: true,
      menu: false,
      details: {
        title: "Log in to your Workbean Account",
        og: {
          title: "Log in to your Workbean Account",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Log in to your Workbean Account",
        },
      },
    },
  },
  {
    path: "/signup",
    name: "Signup Page",
    component: SignupPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Sign up for a Workbean Account",
        og: {
          title: "Sign up for a Workbean Account",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Sign up for a Workbean Account",
        },
      },
    },
  },
  {
    path: "/verify",
    name: "Verify Page",
    component: VerifyPage,
    meta: {
      requiresAuth: true,
      guestOnly: false,
    },
  },
  {
    path: "/set-password",
    name: "Set Password",
    component: SetPasswordPage,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      guestOnly: true,
      details: {
        title: "Reset Your Password - Workbean",
        og: {
          title: "Reset Your Password - Workbean",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Reset Your Password - Workbean",
        },
      },
    },
  },
  {
    path: "/details",
    name: "Details Page",
    component: DetailsPage,
  },
  {
    path: "/terms",
    name: "Terms and Condition",
    component: TermsAndCondition,
    meta: {
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Terms & Conditions by Workbean",
        og: {
          title: "Terms & Conditions by Workbean",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Terms & Conditions by Workbean",
        },
      },
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy and Policy",
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Privacy Policy by Workbean",
        og: {
          title: "Privacy Policy by Workbean",
          url: window.location.href,
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          canonical: window.location.href,
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
          title: "Privacy Policy by Workbean",
        },
      },
    },
  },
  ...survey,
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/not-found",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem("WBSAASAPP_TK") && !to.fullPath.includes("/login") && !to.fullPath.includes("/signup") && !to.fullPath.includes("/request-reset-password")) {
    localStorage.setItem("saved-route", to.fullPath)
  }
  if (to.meta.requiresAuth && !localStorage.getItem("WBSAASAPP_TK")) {
    localStorage.setItem("URL", to.path)
    next({ name: "Login As", query: { redirect: to.fullPath } })
  } else if (to.meta.guestOnly && localStorage.getItem("WBSAASAPP_TK")) {
    next({ name: "WorkbeanWorld", query: { redirect: to.fullPath } })
  } else if ((to.fullPath.includes("/workbean-world/employee-ambassadorship/") || to.fullPath.includes("/workbean-world/thought-leadership/")) && from.path === "/" && !from.name) {
    localStorage.setItem("fromEmpty", "true")
    next()
  } else next()
})

export default router
