<template>
  <ion-page>
    <ion-content>
      <div data-view class="pb-24">
        <section class="bg-primary-violet text-white px-4 py-10 shadow-xl">
          <h1 class="text-3xl font-thin">Privacy Policy</h1>
        </section>
        <div class="w-11/12 mx-auto">
          <section class="my-4">
            <div class="content">
              <small>Effective October 12, 2020</small>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">We value your privacy</h2>
              <p class="mt-4">
                Workbean’s mission is to create Asia’s largest directory of company cultures to empower professionals to find opportunities in companies where they will belong. To make this possible,
                we need to collect some personal and professional information to give you the best experience. Workbean is committed to provide transparency about the data we collect about you, how it
                is used and with whom it is shared.
              </p>
              <p class="mt-4">
                This privacy policy applies when you use Workbean’s Services (as seen below).Workbean users and visitors are offered choices about the data we can collect, use and share as described
                in this Privacy Policy, our Cookie Policy and User Settings.
              </p>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">Introduction</h2>
              <p class="mt-4">
                <span class="font-bold">Workbean Philippines Corporation</span>
                ("Workbean," "we," "us," or "our,") values the privacy of our members, visitors and employers. We ensure that we remain transparent in how we manage personal data which refers to
                personal information, sensitive personal information and privileged information that are defined according to the Philippines’ Privacy Law, the Data Privacy Act of 2012 ("DPA") and its
                implementing rules and regulations.
              </p>
              <p class="mt-4">
                Our registered users ("Members") share their professional identities, connect with fellow members and network, post and view relevant content, exchange information, knowledge and
                professional insights, view company profiles and find career opportunities.
              </p>
              <p class="mt-4">Content and data on some of our services is viewable to non-members ("Visitors").</p>
              <p class="mt-4">
                Companies registered on Workbean (" Employers") are business entities that have an account on Workbean that are featured through their Culture Pages. Employers can post photos and
                videos, share their culture story, and post jobs and accept applications from our Members.
              </p>
              <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Services</h3>
              <p class="mt-2">This Privacy Policy applies to Workbean.co and services ("Services"), including our ad services.</p>
              <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Changes</h3>
              <p class="mt-2">
                Workbean can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review
                the changes before they become effective. If you object to any changes, you may choose to delete your account under your User Settings for our Members, or contact your account manager
                for the Employers.
              </p>
              <p class="mt-2">
                You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your
                personal data is subject to the updated Privacy Policy, as of its effective date.
              </p>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">What information we collect</h2>
              <p class="mt-4">
                We collect personal and non-personal data from you when you use our Services. Personal Data is data that includes a personal identifier like your name, email or address, phone number,
                IP address or device identifier, or is data that could reasonably be linked back to you. It also may include demographic information, geolocation information, educational information,
                or commercial information as described below.
              </p>
              <p class="mt-4">
                You need to have an account to use Workbean’s full services such as applying to jobs or following industry experts. You can create an account on Workbean as a job seeker seeking an
                opportunity or career advice or as an employer looking for great talent. You may also create an account as a Workbean influencer or industry expert ("Beanfluencer").
              </p>
              <ul class="list-disc pl-16">
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Job seeker data</h3>
                  <p class="mt-1">
                    We receive personal and sensitive personal data about you when you create a Job Seeker account, update your profile, follow influencers, read or create articles, view or follow
                    companies, view or apply to jobs, respond to questions, quizzes or surveys on our website or otherwise use our Site, from our Partner Employers, and from other third party data
                    sources.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Employer data</h3>
                  <p class="mt-1">
                    Our Partner Employers share information about their company, people and culture with us so we can provide them with services that they use to manage their employer branding
                    strategies. Each Partner Employer chooses what it shares, which may include benefits, amenities, core values, mission, vision, company photos and videos and other pertinent
                    information required to showcase their culture to aspiring job seekers.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Your profile, your account</h3>
                  <p class="mt-1">
                    You can choose to share additional information with us, such as an updated email address, phone or mobile number, work experience, resume, portfolio and answers to questions about
                    your interests and activities that will be covered by this Privacy Policy and our Terms of Service. You can also share this information by updating your profile, uploading
                    documents that include personal information, or answering quizzes or surveys we email you or present to you on the Services.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">From your status comments, reactions or engagements and employer reviews*</h3>
                  <p class="mt-1">
                    We receive any personal information you choose to include in submitted employer reviews, post comments, reactions or engagements. Wokrbean collects this information whether you
                    choose to make your review public or unpublished for employer reviews.
                    <span class="block">*Coming soon</span>
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Usage and log data</h3>
                  <p class="mt-1">
                    When you visit our Site, we log and store your IP Address and technical information about your visit like your browser type, OS, how you progressed through the Site, where you
                    abandoned it or what pages you visited ("Usage Data"). We use your IP address to determine your general location but never your exact location
                  </p>
                </li>
              </ul>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">How we use your personal information</h2>
              <p class="mt-4">
                We use your data to personalize your experience, provide support and improve our services (including ads and smart suggestions). How you use our Services will depend on the choices you
                make under your settings page.
              </p>
              <ul class="list-disc pl-16">
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Services</h3>
                  <p class="mt-1">We use your data depending on your settings or preferences to improve your overall experience.</p>
                  <h4 class="mt-4 font-semibold">Connecting to Opportunities</h4>
                  <p class="mt-1">
                    Our Services allow you to explore careers, evaluate career pathways and opportunities, and learn from other members in the platform. Our Services also allow you to find jobs that
                    are relevant to your skills, experience, personality, values and preference. Your profile can be found by those looking to hire or to look for professional connections. We will use
                    your data based on your article consumption, people you follow or interests you provide to recommend jobs to you and you to recruiters. We may use automated systems to provide
                    content and recommendations to help make our Services more relevant to our Members, Visitors and Partner Employers.
                  </p>
                  <h4 class="mt-4 font-semibold">Keep Connected</h4>
                  <p class="mt-1">
                    Our Services allow you to stay informed about news, events and ideas regarding professional topics you care about, and from professionals you follow. We use the data we have about
                    you (e.g., data you provide, data we collect from your engagement with our Services and inferences we make from the data we have about you), to personalize our Services for you,
                    such as by recommending or ranking relevant content and conversations on our Services.
                  </p>
                  <p class="mt-1">
                    We also use the data we have about you to suggest skills you could add to your profile and skills that you might need to pursue your next opportunity. So, if you let us know that
                    you are interested in a new skill (e.g., by reading up on a role description or following an industry expert "Beanfluencer"), we will use this information to personalize content in
                    your feed, suggest that you follow certain members on our site, or suggest related content to help you towards that new skill. We use your content, activity and other data,
                    including your name and photo, to provide notices to your network and others. For example, subject to your settings, we may notify others that you have updated your profile, posted
                    content, took a social action, used a feature, made new connections or were mentioned within the platform.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Communications</h3>
                  <p class="mt-1">
                    We will contact you through email, mobile phone, notices posted on our websites or apps, messages to your Workbean inbox, and other ways through our Services. We will send you
                    messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use our Services, network updates, reminders, job
                    suggestions and promotional messages from us and our partners. You may change your communication preferences any time. Please be aware that you cannot opt out of receiving service
                    messages from us, including security and legal notices.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Marketing</h3>
                  <p class="mt-1">
                    We use our members' data and content for our own marketing efforts in promoting Workbean as a new destination for career development and culture fit alignment. We will also use
                    your data to let your connections or contacts know that you have joined the Workbean platform.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Product Development and Research</h3>
                  <p class="mt-1">We use our members' and partner employers’ data and content to work on product improvements, feature releases, bug fixes, new product releases and research.</p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Customer Support</h3>
                  <p class="mt-1">We use data (which can include your communications) to investigate, respond and to resolve complaints or issues for you.</p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">General Member Insights</h3>
                  <p class="mt-1">
                    We use your data to produce and share industry insights that do not directly identify you. We may use your data to generate general statistics about our members, their profession
                    or industry, to calculate ad impressions served or clicked on, or to publish visitor demographics for a Service or create demographic workforce insights.
                  </p>
                </li>
              </ul>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">When we may share your personal information</h2>
              <p class="mt-4">Your personal information and sensitive personal information may only be shared under the following circumstances:</p>
              <ul class="list-disc pl-16">
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Services</h3>
                  <p class="mt-1">We use your data depending on your settings or preferences to improve your overall experience.</p>
                  <h4 class="mt-4 font-semibold">With your consent</h4>
                  <p class="mt-1">
                    For example, you may choose to opt in for an email marketing subscription to receive job alerts or company updates to help you with your job search. Our Partner Employers are
                    required to only use Worbean’s data to provide employment services and to safeguard any personal data they receive. When posting an employer review or other related content, you
                    may choose to publish your name and profile alongside the content publicly.
                  </p>
                  <h4 class="mt-4 font-semibold">For Vendors</h4>
                  <p class="mt-1">
                    With vendors that are contractually engaged to provide us with services, such as cloud service providers, Services related telecommunications, and other services for maintaining
                    our systems, order fulfillment, email management and credit card processing. These companies are obligated by contract to safeguard any personal data they receive from us.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">For Law Enforcement</h3>
                  <p class="mt-1">
                    With relevant law enforcement bodies if we believe that disclosure is reasonably necessary to comply with a law, regulation, valid legal process (e.g., subpoenas or warrants served
                    on us), or governmental or regulatory request, to enforce or apply the Terms of Service, to protect the security or integrity of the Handshake Service, and/or to protect the
                    rights, property, or safety of Handshake, its employees, users, or others. If we are going to release your data, we will do our best to provide you with notice in advance by email,
                    unless we are prohibited by law from doing so.
                  </p>
                </li>
                <li>
                  <h3 class="mt-4 text-xl font-bold text-wizard-worksona">Investors or Other Professional Services</h3>
                  <p class="mt-1">
                    With professional services, potential purchasers or investors in connection with the sale, merger, bankruptcy, sale of assets or reorganization of our company. We will notify you
                    if a different company will receive your personal data and the promises in this Privacy Policy will apply to your data as transferred to the new entity.
                  </p>
                </li>
              </ul>
            </div>
            <div class="content mt-10">
              <h2 id="cookies" class="text-md text-2xl text-wizard-worksona font-bold mb-4">Cookies</h2>
              <p class="mt-4">
                As a Site visitor, we place a small piece of software referred to as a "session cookie" on your computer. If you use the Services and create an account, we also use this cookie to
                recognize you on return visits and expedite the login process. You can remove this and other cookies through your browser preferences menu, though the exact method varies depending on
                the type of browser you use – but note that you won’t be able to log into our service if your cookies are disabled. If you visit from a mobile device, we may reference your device
                identifier or other information about where your visit originated.
              </p>
              <p class="mt-4">
                Our email communications contain web beacons or similar technology which track when the email has been opened or read. This functionality sends the resulting records back to our
                Service, which may be associated with other information you have provided us.
              </p>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">How to opt-out of email communication</h2>
              <p class="mt-4">To stop receiving notifications or promotions, please click the unsubscribe link found at the bottom of each email or update your account preferences.</p>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">Storage, security and how to remove your information</h2>
              <p class="mt-4">
                We use industry standard technical, administrative and physical controls to protect your data including encrypting it at rest and as it is transferred from you to Workbean. While we
                take reasonable precautions against possible security breaches, no website or internet transmission is completely secure and we cannot guarantee that unauthorized access, hacking, data
                loss or other breach will never occur. In the event of a breach, we will take reasonable steps to investigate the situation and, where appropriate, notify affected individuals in
                accordance with the Data Privacy Act 2012 or any applicable laws and regulations.
              </p>
            </div>
            <div class="content mt-10">
              <h2 class="text-md text-2xl text-wizard-worksona font-bold mb-4">Contact us and accessing your information</h2>
              <p class="mt-4">
                If you have any questions about our privacy practices, or if you wish to remove any of your personal information, or raise a concern please contact our Data Protection Officer using
                the information below and please expect a reply from us without undue delay:
              </p>
            </div>
            <div class="content mt-10">
              <h2 class="font-semibold text-xl">Data Protection Officer</h2>
              <span class="block"> Workbean Philippines Corporation </span>
              <address class="block">
                <span class="block">5/F Phinma Plaza, Acceler8 Rockwell,</span>
                <span class="block">Rockwell Center, Makati City,</span>
                <span class="block">Philippines</span>
              </address>
              <span class="block">Email: <a href="mailto:kass@workbean.co" class="text-primary-violet">kass@workbean.co</a></span>
            </div>
          </section>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
