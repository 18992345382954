import { defineStore } from "pinia"
import axios from "axios"
import { useTokenStore } from "./token"
import jwt from "@/utils/jwt"
import { first, sortBy } from "lodash"
import { toastController } from "@ionic/vue"

const root = "resources"

interface BATFormInput {
  id: string
  name: string
}
interface thoughtLeadershipInput {
  topic: string
  topic_id: string
  date_expiry: string
  tags: string[]
}
interface formatBATArray {
  benefits: Array<BATFormInput>
  amenities: Array<BATFormInput>
  technologies: Array<BATFormInput>
}

interface EBPFormat {
  color: string
  description: {
    full: string
    peak: string
  }
  id: string
  image: {
    circle: string
    full: string
  }
  name: string
}

interface surveyDescriptionsInput {
  [key: string]: {
    title: string
    progressLabel: string
    rule: string
    tip: string
    details: Array<{
      label: string
      description: string
      color: string
    }>
  }
}
interface surveyInput {
  question: {
    type: string
    field: string
    label: string
    answer?: string | number | boolean
    option?: { [key: string]: string | number | boolean }
  }
  id: string
  type: string
}
export type worksonaCodeType = "explorer" | "innocent" | "innovator" | "inventor" | "jester" | "philosopher" | "realist" | "supporter" | "sweetheart" | "warrior" | "ruler" | "rebel"

export const useResourcesStore = defineStore("resources", {
  state: () => ({
    thoughtLeadership: <thoughtLeadershipInput>{
      topic: "",
      topic_id: "",
      date_expiry: "",
      tags: [],
    },
    industries: [] as any[],
    events: [],
    ebp: <EBPFormat[]>[],
    selection: <formatBATArray>{
      amenities: [],
      benefits: [],
      technologies: [],
    },
    survey: <surveyInput[]>[],
    surveyDescriptions: <surveyDescriptionsInput>{
      worksona: {
        title: "Discover your Worksona",
        progressLabel: "Worksona in progress",
        rule: "Select the item that best describes you at work",
        tip: "Important note: You can only take this quiz every 90 days as motivations may change based on your situation in a certain time period",
        details: [
          {
            label: "Complete the test",
            description: "Be yourself and answer honestly to find out your personality type.",
            color: "#E14D83",
          },
          {
            label: "View detailed results",
            description: "Learn how your personality type influences many areas of your life.",
            color: "#FFAE00",
          },
          {
            label: "Discover the way you work",
            description: "Grow into the person you want to be by discovering the way you present yourself.",
            color: "#7442C8",
          },
        ],
      },
    },
    worksona: {
      archetype: "",
      codename: <worksonaCodeType>"",
      color: "",
      description: "",
      fear: "",
      icon: "",
      image: "",
      image_with_icon: "",
      influential_figure: "",
      life_quote: "",
      motivation: "",
      name: "",
      problem_solving_mechanism: "",
      sneak_peak: "",
      pitfalls: ["", "", ""],
      talents: ["", "", ""],
    },
    resourcesFilters: {
      offset: 0,
      format: "all",
      audience: "All",
      topic: "All",
    },
  }),
  actions: {
    /**
     *
     * @param newValue - new value (object)
     *
     * sets resources filters
     */
    setResourcesFilters(newValue: any) {
      this.resourcesFilters = newValue
    },

    /**
     *
     * Retrives resource details of thought leadership
     */
    async retrieveThoughtLeadershipTopic() {
      try {
        const header = useTokenStore().authorizationHeader
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/thought-leadership/topics`, header)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const topics = JSON.parse((parsed.payloadObj as any).sub)
          if (topics.length) {
            const topic = <any>first(topics)
            this.thoughtLeadership = {
              topic: topic.topic,
              topic_id: topic.topic_id,
              date_expiry: topic.date_expiry,
              tags: topic.tags,
            }
          } else {
            this.thoughtLeadership.topic_id = "none"
          }
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
    /**
     * retrieve industry
     */
    async retrieveIndustries() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/industries`)
        const parsed: any = jwt.parseToken(response.data)
        if (parsed) {
          const industriesData = JSON.parse(parsed!.payloadObj!.sub)
          this.industries = industriesData
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
    /**
     *
     * @param resourceType - get value either of amenities, technologies or benefits
     * retrieve data whether its amenities, technologies or benefits
     */
    async retrieveBAT(resourceType: keyof formatBATArray) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/${resourceType}`)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          this.selection[resourceType] = sortBy(JSON.parse((parsed.payloadObj as any).sub), ["name"])
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     * Retrieves Workbean events
     */
    async retrieveEvents() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/events`)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          this.events = JSON.parse((parsed.payloadObj as any).sub)
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
    /**
     * Retrieves Workbean EBP list
     */
    async retrieveEBPList() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/ebp`)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          this.ebp = JSON.parse((parsed.payloadObj as any).sub)
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
    /**
     * Retrieves Workbean Survey
     * @param type - survey type
     */
    async retrieveSurvey(type: string) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/survey/${type}`)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          this.survey = JSON.parse((parsed.payloadObj as any).sub)
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
    /**
     * Retrieves Worksona Result
     * @param type - worksona
     * @param asReturn - if system will return as data, not saved in state management
     */
    async retrieveWorksonaResult(type: string, asReturn = false) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/worksona/result/${type}`)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          if (asReturn) return JSON.parse((parsed.payloadObj as any).sub).data
          else this.worksona = JSON.parse((parsed.payloadObj as any).sub).data
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     * retrieve resources data from builder
     * @param params - filter params (object)
     *
     * returns array of resources data
     */
    async retrieveResources(params: { offset: number; format: string; audience: string; topic: string }) {
      try {
        const response = await axios.get(
          `https://cdn.builder.io/api/v2/content/page?apiKey=${process.env.VUE_APP_RESOURCES_KEY}&sort.createdDate=-1&limit=10&offset=${params.offset}${
            params.format.length && params.format !== "all" ? `&query.data.format=${params.format}` : ""
          }${params.audience.length && params.audience !== "All" ? `&query.data.audience=${params.audience}` : ""}${
            params.topic.length && params.topic !== "All" ? `&query.data.topic=${params.topic}` : ""
          }`
        )
        return response.data.results || []
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
  },
})
