import { defineStore } from "pinia"
import axios from "axios"
import jwt from "@/utils/jwt"
import router from "@/router"
import { toastController } from "@ionic/core"

import { useTokenStore } from "./token"
const root = "newsfeed"

export interface User {
  employment: {
    department: string
    role: string
  }
  name: {
    first_name: string
    full_name: string
    last_name: string
  }
  photo: string
}

interface AmbassadorshipAnswer {
  answer: string
  hide: boolean
  question: string
  topic: string
}
export interface Company {
  location?: unknown
  brand_color: string
  logo: string
  name: string
  slug: string
}

export interface Answer {
  account_id: string
  answer: string
  answer_id: string
  answers: AmbassadorshipAnswer[]
  associated_company: string
  created: string
  display_link: string
  likes: string[]
  status: string
  tags: string[]
  topic: string
  topic_id: string
  updated: string
}
interface Feed {
  content: {
    html: string
  }
  type: string
  account_id: string
  newsfeed_id: string
  created: string
  likes: string[]
  user: User
  company: Company
  answer_content: {
    answer: Answer
    company: Company
    user: User
  }
}

export const useNewsFeedStore = defineStore("newsfeed", {
  state: () => ({
    newsfeed_id: "", // if update
    account_id: "", // creator (supplied in backend)
    type: "",
    content: {
      image: "",
      text: "",
      audio: "",
      video: "",
      answer_id: "", // if type is company
    },
    newsfeedList: [] as Array<Feed>,
    currentFeed: [] as Array<Feed>,
    currentPost: [],
    thoughtHighlights: [],
    isLast: false,
    isNewState: false,
  }),
  actions: {
    /**
     *
     * @param params  - filter query
     * @returns true when retrieve is success
     *
     *
     */
    async retrieveFeed(params: any) {
      try {
        const config = <any>useTokenStore().authorizationHeader
        config.params = params
        this.isLast = true
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/getfeed`, config)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          if (this.isNewState) this.newsfeedList = data
          else this.newsfeedList = [...this.newsfeedList, ...data]
          this.isLast = data.length ? false : true
        }

        delete config.params
        return true
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
        if (error.response?.data?.message === "Could not find company!") router.replace({ path: "/not-found" })
      }
    },

    /**
     *
     * @param feed_id  - id of post
     * @returns true if share capture is a success
     *
     * Calls on share post api to record that post has been shared
     */
    async shareFeed(feed_id: string, external?: string | number) {
      try {
        const config = <any>useTokenStore().authorizationHeader
        config.params = { feed: feed_id, external: external }
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/share-post`, null, config)
        delete config.params
        return true
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @param feed_id  - id of post
     * @returns true if like capture is a success
     *
     * Calls on like post api to record like on post
     */
    async likeFeed(feed_id: string) {
      try {
        const config = <any>useTokenStore().authorizationHeader
        config.params = { feed: feed_id }
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/like-post`, null, config)
        delete config.params
        return true
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @returns true when data retrieve is a success
     *
     * Calls on higlights api to retrieve thought leadership highlight question and answers
     */
    async retrieveTLHighlights() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/thought-leadership/highlights`)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          this.thoughtHighlights = data
        }
        return true
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
  },
})
